const config = require('shared/config/shared');
const io = require('socket.io-client');
const UserService = require('services/UserService');
const EventHub = require('shared/utils/event-hub');
const EntryDatastore = require('datastores/EntryDatastore');

const EntryService = {
  socket: null,
  events: new EventHub(),

  setContent(content) {
    if(!this.socket) return;

    this.socket.emit('replace', {
      content: content,
    });
  },

  connect(id, gmMode = false) {
    this.disconnect();
    this.socket = io(`${location.protocol}//${location.host}`, {
      query: `r_var=${id}&u_var=${UserService.id}`,
    });

    if(gmMode) {
      this.socket.on('gm:update', (data) => {
        let delta = JSON.parse(data.delta);
        this.events.trigger('gm:update', delta);
      });

      this.socket.on('gm:replace', (data) => {
        let content = data.gmContent;
        this.events.trigger('gm:replace', content);
      });

    } else {

      this.socket.on('update', (data) => {
        let delta = JSON.parse(data.delta);
        this.events.trigger('update', delta);
      });

      this.socket.on('replace', (data) => {
        let content = data.content;
        this.events.trigger('replace', content);
      });

    }
  },

  disconnect() {
    if(!this.socket) return;

    this.socket.disconnect();
    this.socket = null;
    this.events = new EventHub();
  },

  updateContent({ delta, content }) {
    if(!this.socket) return;

    this.socket.emit('update', {
      delta: JSON.stringify(delta.ops),
      content: content.ops,
    });
  },

  updateGmContent({ delta, gmContent }) {
    if(!this.socket) return;

    this.socket.emit('gm:update', {
      delta: JSON.stringify(delta.ops),
      gmContent: gmContent.ops,
      gmPassword: config.gmPassword,
    });
  },

  setGmContent(content) {
    if(!this.socket) return;

    this.socket.emit('gm:replace', {
      gmContent: content,
      gmPassword: config.gmPassword,
    });
  },
};

module.exports = EntryService;