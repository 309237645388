const dom = require('shared/utils/dom');
const ModalComponent = require('components/ModalComponent');

function formatDate(timestamp) {
  return new Date(timestamp).toLocaleString('en-us', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

class HistoryModal {
  constructor(params) {
    this.params = Object.assign({
      history: [],
    }, params);
  }

  classes() {
    return ['history-modal'];
  }

  renderHeader() {
    return `<h2 class="modal-title">History for ${this.params.title}</h2>`;
  }

  renderBody() {
    let history = this.params.history.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    return `<div class="history">
      <ul class="history-list">
        <li class="version history-entry current" data-index="-1">Current</li>
        ${history.map((version, i) => {
          return `<li class="version history-entry" data-index="${i}">${formatDate(version.timestamp)}</li>`
        }).join('')}
      </ul>
      <div id="history-preview">
        <div class="quill-always-hidden-toolbar"></div>
        <div id="history-preview-editor"></div>
      </div>
    </div>`;
  }

  renderFooter() {
    return `<div class="action-bar">
      <button id="modal-cancel" class="modal-action secondary">Cancel</button>
      <button id="modal-save" class="modal-action primary" disabled>Restore</button>
    </div>`;
  }

  bindEvents(el) {
    this.saveButton = el.querySelector('#modal-save');
    this.currentVersion = null;

    this.quill = new window.Quill('#history-preview-editor', {
      theme: 'snow',
      readOnly: true,
      modules: {
        toolbar: '.quill-always-hidden-toolbar',
      }
    });
    
    el.querySelector('#modal-cancel').addEventListener('click', () => {
      ModalComponent.hide();
    });
    this.saveButton.addEventListener('click', () => {
      this.params.onRestore(this.currentVersion);
    });

    dom.delegate(el, 'click', {
      '.history-entry': this.onHistoryClicked
    }, this);

    this.onHistoryClicked({
      target: el.querySelector('.history-entry.current'),
    });
  }

  onHistoryClicked(e) {
    let index = e.target.getAttribute('data-index');
    if(index == -1) {
      this.quill.setContents(this.params.current);
      this.currentVersion = null;
      this.saveButton.setAttribute('disabled', 'true');
    } else {
      this.currentVersion = this.params.history[index];
      this.quill.setContents(this.currentVersion.content);
      this.saveButton.removeAttribute('disabled');
    }
    if(this.activeEl) {
      this.activeEl.classList.remove('active');
    }
    this.activeEl = e.target;
    this.activeEl.classList.add('active');
    console.log('preview this revision', this.currentVersion);
  }
}

module.exports = HistoryModal;