const { debounce } = require('shared/utils/functions');
const EventHub = require('shared/utils/event-hub');

const MOUNT_SELECTOR = '#headings-search';

const HeadingsFilterComponent = {
  el: null,
  inputEl: null,
  events: new EventHub(),

  mount() {
    this.el = document.querySelector(MOUNT_SELECTOR);
    this.inputEl = this.el.querySelector('#headings-search-text');

    this._onSubmit = this._onSubmit.bind(this);
    this._onKeydown = this._onKeydown.bind(this);
    this._debouncedOnKeydown = debounce(this._onKeydown, 250);

    this.el.addEventListener('submit', this._onSubmit);
    this.inputEl.addEventListener('keydown', this._debouncedOnKeydown);
  },

  unmount() {
    if(this.el) {
      this.el.removeEventListener('submit', this._onSubmit);
    }

    if(this.inputEl) {
      this.inputEl.removeEventListener('keydown', this._debouncedOnKeydown);
    }

    this.el = null;
    this.inputEl = null;
    this._debouncedOnKeydown = null;
    this.events = new EventHub();
  },

  _onSubmit(e) {
    e.preventDefault();
  },

  _onKeydown() {
    let query = this.inputEl.value;
    this.events.trigger('filter', query);
  }
};

module.exports = HeadingsFilterComponent;