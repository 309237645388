class Datastore {
  constructor() {
    this.data = null;
    this.listeners = [];
    this.cid = 0;
  }

  _nextId() {
    return this.cid++;
  }

  listen(callback) {
    callback.id = this._nextId();
    this.listeners.push(callback);
  }

  unlisten(callback) {
    let i = this.listeners.findIndex(c => callback.id === c.id);
    this.listeners.splice(i, 1);
  }

  triggerListeners(oldData) {
    this.listeners.forEach(callback => {
      callback(this.data, oldData);
    });
  }

  get() {
    return this.data;
  }

  update(data) {
    let oldData = this.data;
    this.data = data;
    this.triggerListeners(oldData);
  }
}

module.exports = Datastore;