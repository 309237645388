const http = require('shared/utils/http-rest');
const AppComponent = require('components/AppComponent');
const EntryDatastore = require('datastores/EntryDatastore');

document.addEventListener('DOMContentLoaded', (e) => {
  AppComponent.mount();

  http.get('/api/graph')
    .then(graph => {
      EntryDatastore.update(graph);
    });
});