const io = require('socket.io-client');
const config = require('shared/config/dev');
const http = require('shared/utils/http-rest');
const EntryService = require('services/EntryService');
const EntryActions = require('actions/EntryActions');
const EntryDatastore = require('datastores/EntryDatastore');
const CurrentEntryDatastore = require('datastores/CurrentEntryDatastore');
const { insertLinks } = require('utils/quill');
const { debounce } = require('shared/utils/functions');
const { isLinkExternal } = require('utils/location');

const MOUNT_SELECTOR = '#content';

const EditorComponent = {
  el: null,
  gmMode: false,

  mount() {
    this.el = document.querySelector(MOUNT_SELECTOR);

    EntryActions.events.on('selectEntry', this.loadDatum.bind(this));
    EntryActions.events.on('autolink', this.searchForHeadingsAndInsertLinks.bind(this));
    EntryActions.events.on('enable-gm-mode', this.enableGmMode.bind(this));
    EntryActions.events.on('disable-gm-mode', this.disableGmMode.bind(this));

    CurrentEntryDatastore.listen(this.reconstruct.bind(this));
    this.interceptQuickLinks();
  },

  createQuill() {
    this.el.innerHTML = `<div id="editor"></div>`;
    this.quill = new window.Quill('#editor', {
      theme: 'snow'
    });
  },

  deconstruct() {
    EntryService.disconnect();
  },

  reconstruct(datum) {
    this.createQuill();
    this.datum = datum;
    EntryService.disconnect();
    EntryService.connect(datum._id, this.gmMode);

    if(this.gmMode) {
      this.reconstructGm(datum);
    } else {
      this.reconstructNormal(datum);
    }
  },

  reconstructNormal(datum) {
    this.quill.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user') {
        debounce(this.searchForHeadingsAndInsertLinks.bind(this), 500)();
      }

      if(source !== 'api') {
        EntryService.updateContent({
          delta,
          content: this.quill.getContents(),
        });
      }
    });

    if(datum.content) {
      this.quill.setContents(datum.content);
    }

    EntryService.events.on('update', (delta) => {
      this.quill.updateContents(delta);
    });

    EntryService.events.on('replace', (content) => {
      this.quill.setContents(content);
    });
  },

  reconstructGm(datum) {
    this.quill.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user') {
        debounce(this.searchForHeadingsAndInsertLinks.bind(this), 500)();
      }

      if(source !== 'api') {
        EntryService.updateGmContent({
          delta,
          gmContent: this.quill.getContents(),
        });
      }
    });

    if(datum.gmContent) {
      this.quill.setContents(datum.gmContent);
    }

    EntryService.events.on('gm:update', (delta) => {
      this.quill.updateContents(delta);
    });

    EntryService.events.on('gm:replace', (content) => {
      this.quill.setContents(content);
    });
  },

  searchForHeadingsAndInsertLinks() {
    insertLinks(this.quill, EntryDatastore.data, (id) => {
      return `/${EntryDatastore.pathTo(id).join('/')}`;
    });
  },

  loadDatum(id) {
    if(!id) {
      return;
    }

    this.deconstruct();
  },

  interceptQuickLinks() {
    this.el.addEventListener('click', (e) => {
      if(e.target.classList.contains('ql-preview')) {
        let href = e.target.href;
        if(!isLinkExternal(href)) {
          e.preventDefault();
          let id = EntryDatastore.getIdFromPath(href);
          EntryActions.selectEntry(id);
        }
      }
    });
  },

  enableGmMode() {
    this.gmMode = true;
    this.deconstruct();
    this.reconstruct(this.datum);
  },

  disableGmMode() {
    this.gmMode = false;
    this.deconstruct();
    this.reconstruct(this.datum);
  },
};

module.exports = EditorComponent;