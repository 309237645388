import { mix } from './objects';

/**
 * Execute an AJAX request.
 *
 * @param {string} method The HTTP method to use.
 * @param {string} url The URL to send teh request to.
 * @param {object=} params Key-value paris of query string parameters.
 * @param {*=} data The request body.
 * @param {headers=} headers Any headers to set.
 * @returns {Promise}
 */
function http({ method, url, params, data, headers = {} }) {
    return new Promise((resolve, reject) => {
        let client = new XMLHttpRequest();
        let uri = url;

        if(params) {
            let queryString = '';
            for(let key in params) {
                if(!params.hasOwnProperty(key)) continue;
                let symbol = queryString ? '&' : '?';
                let s = `${symbol}${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
                queryString += s;
            }
            uri += queryString;
        }

        client.open(method, uri);

        for(let key in headers) {
            if(headers.hasOwnProperty(key)) {
                let value = headers[key];
                client.setRequestHeader(key, value);
            }
        }
        let keys = Object.keys(headers).map(key => key.toLowerCase());
        if(typeof data === 'object' && keys.indexOf('content-type') == -1) {
            client.setRequestHeader('Content-Type', 'application/json');
        }

        if(data && (method == 'POST' || method == 'PUT' || method == 'PATCH')) {
            if(typeof data === 'object') {
                client.send(JSON.stringify(data));
            } else {
                client.send(data);
            }
        } else {
            client.send();
        }

        client.onload = function() {
            let contentType = client.getResponseHeader('Content-Type');
            let json = contentType ? contentType.startsWith('application/json') : false;
            let body = json ? JSON.parse(this.response) : this.response;

            if(this.status >= 200 && this.status < 300) {
                resolve(body, {
                    status: this.status,
                    body
                });
            } else {
                reject({
                    status: this.status,
                    body
                });
            }
        };

        client.onerror = function() {
            let json = client.getResponseHeader('Content-Type').startsWith('application/json');
            let body = json ? JSON.parse(this.response) : this.response;

            reject(body, {
                status: this.status,
                body
            })
        };
    })
}

/**
 * Executes a GET request to the specified location.
 *
 * @param {string} url The URL to send the request to.
 * @param {object=} config The full configuration options.
 * @returns {Promise}
 */
http['get'] = (url, config = {}) => http(mix({
    url,
    method: 'GET'
}, config));

/**
 * Executes a POST request to the specified location.
 *
 * @param {string} url The URL to send the request to.
 * @param {*=} data The request body.
 * @param {object=} config The full configuration options.
 * @returns {Promise}
 */
http['post'] = (url, data, config = {}) => http(mix({
    url,
    data,
    method: 'POST'
}, config));

/**
 * Executes a PATCH request to the specified location.
 *
 * @param {string} url The URL to send the request to.
 * @param {*=} data The request body.
 * @param {object=} config The full configuration options.
 * @returns {Promise}
 */
http['patch'] = (url, data, config = {}) => http(mix({
    url,
    data,
    method: 'PATCH'
}, config));

/**
 * Executes a PATCH request to the specified location.
 *
 * @param {string} url The URL to send the request to.
 * @param {object=} config The full configuration options.
 * @returns {Promise}
 */
http['delete'] = (url, config = {}) => http(mix({
  url,
  method: 'DELETE'
}, config));

module.exports = http;