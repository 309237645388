const fn = require('fnjs');
const dom = require('shared/utils/dom');
const EntryDatastore = require('datastores/EntryDatastore');
const UsersDatastore = require('datastores/UsersDatastore');
const EditorComponent = require('components/EditorComponent');
const icons = require('svg-icons');
const EntryActions = require('actions/EntryActions');
const ModalComponent = require('components/ModalComponent');
const NewEntryModal = require('modals/NewEntryModal');
const MoveEntryModal = require('modals/MoveEntryModal');
const TextInputModal = require('modals/TextInputModal');
const ConfirmModal = require('modals/ConfirmModal');
const TooltipDirective = require('directives/TooltipDirective');
const { childrenRecursive } = require('utils/graph');
const HeadingsFilterComponent = require('components/HeadingsFilterComponent');

const MOUNT_SELECTOR = '#headings';

function getHeadingData(el, data) {
  let headingEl = el.closest('.heading');
  return headingEl ? headingEl.getAttribute(`data-${data}`) : null;
}

function normalizeForFilter(text) {
  return text.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase();
}

const HeadingsComponent = {
  el: null,
  listRootEl: null,
  headings: {},
  collapsedHeadings: {},
  currentHeadingEls: [],

  events: {
    '.heading-text'(e) {
      this.onHeadingClick(getHeadingData(e.target, 'id'));
    },

    '.action.add'(e) {
      this.onAddClick(getHeadingData(e.target, 'id'));
    },

    '.action.remove'(e) {
      this.onRemoveClick(getHeadingData(e.target, 'id'));
    },

    '.action.edit'(e) {
      this.onEditClick(getHeadingData(e.target, 'id'));
    },

    '.action.collapse'(e) {
      this.onCollapseClick(getHeadingData(e.target, 'id'));
    },

    '.action.move'(e) {
      this.onMoveClick(getHeadingData(e.target, 'id'));
    },
  },

  mount() {
    this.el = document.querySelector(MOUNT_SELECTOR);

    EntryDatastore.listen(this.update.bind(this));
    UsersDatastore.listen(this.updateUsers.bind(this));

    EntryActions.events.on('selectEntry', this.selectHeadingEl.bind(this));
    EntryActions.events.on('enable-gm-mode', this.enableGmMode.bind(this));
    EntryActions.events.on('disable-gm-mode', this.disableGmMode.bind(this));

    dom.delegate(this.el, 'click', this.events, this);
  },

  selectHeadingEl(id) {
    let headingEl = this.el.querySelector(`[data-id="${id}"]`);

    this.currentHeadingEls.forEach(el => {
      el.classList.remove('is-active');
    });
    let parent = headingEl.closest('.heading');

    while(parent) {
      this.currentHeadingEls.push(parent);
      parent.classList.add('is-active');
      parent = parent.parentNode.closest('.heading');
    }
  },

  onHeadingClick(id) {
    EntryActions.selectEntry(id);
  },

  onCollapseClick(id) {
    let headingEl = this.el.querySelector(`[data-id="${id}"]`);
    headingEl.classList.toggle('is-collapsed');

    let tooltipEl = headingEl.querySelector('.action.collapse');
    tooltipEl.setAttribute('data-tooltip-text', headingEl.classList.contains('is-collapsed')
      ? 'Expand' : 'Collapse');
    TooltipDirective.rerender(tooltipEl);
  },

  onAddClick(id) {
    ModalComponent.show(new NewEntryModal({
      title: 'New Entry',
      placeholder: 'Fergus Johnson',
      label: 'Title',
      parent: id,
      onSave: ({ value, parent }) => {
        ModalComponent.hide();
        EntryActions.newEntry({
          title: value,
          parent: parent
        });
      }
    }));
  },

  onEditClick(id) {
    let heading = this.headings[id];
    ModalComponent.show(new TextInputModal({
      title: 'Edit Heading Title',
      placeholder: heading.title,
      label: 'Title',
      onSave: ({ value }) => {
        ModalComponent.hide();
        EntryActions.updateEntry(id, {
          title: value
        });
      }
    }));
  },

  onMoveClick(id) {
    let heading = this.headings[id];
    let parentIds = EntryDatastore.idsTo(id);
    ModalComponent.show(new MoveEntryModal({
      title: `Move "${heading.title}"`,
      from: parentIds[parentIds.length - 2],
      onSave: ({ parent }) => {
        ModalComponent.hide();
        EntryActions.moveEntry(id, parent);
      }
    }));
  },

  onRemoveClick(id) {
    let heading = this.headings[id];
    ModalComponent.show(new ConfirmModal({
      title: `Remove "${heading.title}" and all children?`,
      mainActionClass: 'danger',
      onConfirm: ()  => {
        let entries = [...Object.keys(childrenRecursive(this.headings, id)), id];
        ModalComponent.hide();
        EntryActions.removeEntries(entries);
      }
    }));
  },

  onFilter(query) {
    let headings = dom.findAll(this.el, '.heading');

    this.el.classList.remove('is-querying');
    headings.forEach(heading => {
      heading.classList.remove('query-match');
      heading.classList.remove('query-visible');
    });

    if(query) {
      query = normalizeForFilter(query);
      this.el.classList.add('is-querying');

      headings.forEach(heading => {
        let textEl = dom.findOne(heading, '.heading-text');
        if(!textEl) {
          return;
        }

        let text = normalizeForFilter(textEl.innerText);

        if(text.search(query) > -1) {
          heading.classList.add('query-match');

          let parent = heading.parentNode.closest('.heading');
          while(parent) {
            parent.classList.add('query-visible');
            parent = parent.parentNode.closest('.heading');
          }
        }
      });
    }
  },

  update(headings) {
    this.headings = headings;
    this.render();
  },

  render() {
    HeadingsFilterComponent.unmount();

    this.collapsedHeadings = dom.findAll(this.el, '.is-collapsed').reduce((map, el) => {
      let id = el.getAttribute('data-id');
      map[id] = true;
      return map;
    }, {});

    this.el.innerHTML = `
      <div class="headings-heading" flex row>
        <h2 class="headings-title" grow>Dungeon Pad</h2>
        <span class="actions heading-actions" flex flex-end center-items>
          <span class="action dropdown-action add" d-tooltip data-tooltip-text="Add Entry">${icons.plus({ size: 18 })}</span>
        </span>
      </div>
      <div class="headings-search">
        <form id="headings-search">
          <input id="headings-search-text" name="text" placeholder="Filter" />
        </form>
      </div>
      <div id="headings-list"><ul class="heading-list" data-level="0" data-id="null"></ul></div>
    `;
    this.listRootEl = this.el.querySelector('.heading-list[data-level="0"]');
    this.renderHeadings(this.headings, undefined);

    HeadingsFilterComponent.mount();
    HeadingsFilterComponent.events.on('filter', this.onFilter.bind(this));
  },

  renderHeadings(headings, parent) {
    fn.forEach(headings, (heading, id) => {
      if(heading.parent == parent) {
        this.renderHeading(heading, id, this.listRootEl);
        this.renderHeadings(headings, id);
      }
    });
  },

  renderHeading(heading, id, container) {
    let level = 1;
    let isCollapsed = !!this.collapsedHeadings[id];

    if(heading.parent) {
      let parentActionList = container.querySelector(`[data-id="${heading.parent}"] > .heading-main > .heading-actions`);
      container = container.querySelector(`[data-id="${heading.parent}"] > .heading-list`);
      level = parseInt(getHeadingData(container, 'level')) + 1;

      if(!parentActionList.querySelector('.action.collapse')) {
        parentActionList.prepend(dom.createElement(`<span class="action collapse" d-tooltip d-tooltip-bottom data-tooltip-text="Collapse">${icons['chevron']({ size: 20 })}</span>`));
      }
    }

    container.appendChild(dom.createElement(`
      <li class="heading${isCollapsed ? ' is-collapsed' : ''}" data-id="${id}" data-level="${level}">
        <ul class="user-indicators"></ul>
        <div class="heading-main" flex row>
          <span class="heading-text" grow>${heading.title}</span>
          <span class="actions heading-actions" flex flex-end d-dropdown>
            <span class="action menu" d-dropdown-trigger d-tooltip d-tooltip-bottom data-tooltip-text="More Actions">${icons['3dots']({ size: 18 })}</span>
              <div class="heading-actions-dropdown" d-dropdown-target>
              ${level < 3 ? `<span class="action dropdown-action add"><span class="action-label">Add Child</span></span>`: ''}
              <span class="action dropdown-action edit"><span class="action-label">Rename</span></span>
              <span class="action dropdown-action move"><span class="action-label">Move</span></span>
              <span class="action dropdown-action remove"><span class="action-label">Remove</span></span>
            </div>
          </span>
        </div>
        <ul class="heading-list"></ul>
      </li>
    `));
  },

  updateUsers(users) {
    console.log('users changed', users);
    let userIndicators = dom.findAll(this.el, '.user-indicator');
    userIndicators.forEach(indicator => {
      indicator.remove();
    });

    fn.forEach(users, (user, id) => {
      if(user.room) {
        let indicatorContainerEl = dom.findOne(this.el, `[data-id="${user.room}"] > .user-indicators`);
        if(indicatorContainerEl) {
          indicatorContainerEl.append(dom.createElement(`<li class="user-indicator" style="background-color: rgba(${user.color}, 1)"></li>`));
        }
      }
    });
  },

  enableGmMode() {
    this.el.querySelector('.headings-title').innerText = '[GM Mode]';
  },

  disableGmMode() {
    this.el.querySelector('.headings-title').innerText = 'Dungeon Pad';
  },
};

module.exports = HeadingsComponent;