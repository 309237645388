const MainConnector = require('connectors/MainConnector');
const UsersDatastore = require('datastores/UsersDatastore');

const GraphConnector = {
  socket: null,

  init() {
    this.socket = MainConnector.get('main');

    this.socket.on('users-changed', (users) => {
      UsersDatastore.update(users);
    });
  },
};

module.exports = GraphConnector;