/**
 *
 * @returns {Array<string>}
 */
function normalizePath(path) {
  return path.replace(/^\//, '').split('/');
}

function checkDomain(url) {
  if ( url.indexOf('//') === 0 ) { url = location.protocol + url; }
  return url.toLowerCase().replace(/([a-z])?:\/\//,'$1').split('/')[0];
}

function isLinkExternal(url) {
  return ( ( url.indexOf(':') > -1 || url.indexOf('//') > -1 ) && checkDomain(location.href) !== checkDomain(url) );
}

module.exports = {
  normalizePath,
  isLinkExternal
};