const MainConnector = require('connectors/MainConnector');

const SocketService = {

  connectors: [
    MainConnector,
    require('connectors/GraphConnector'),
    require('connectors/UsersConnector'),
  ],

  init() {
    this.connectors.forEach(connector => connector.init());
  },
};

module.exports = SocketService;