const icons = require('svg-icons');

const Selectors = {
  MAIN: '[d-icon]',
};

const DataAttributes = {
  ICON: 'icon',
  SIZE: 'icon-size',
};

class IconDirective {
  constructor(el) {
    this.el = el;
    this.update();
  }

  update() {
    let icon = this.el.getAttribute(`data-${DataAttributes.ICON}`);
    let size = this.el.getAttribute(`data-${DataAttributes.SIZE}`);
    this.icon = size ? icons[icon]({ size }) : icons[icon]();
    this.render();
  }

  render() {
    this.el.innerHTML = this.icon;
  }

  unmount() {

  }
}

IconDirective.selector = Selectors.MAIN;

module.exports = IconDirective;