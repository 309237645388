const EditorComponent = require('components/EditorComponent');
const HeadingsComponent = require('components/HeadingsComponent');
const BreadcrumbsComponent = require('components/BreadcrumbsComponent');
const ModalComponent = require('components/ModalComponent');
const HistoryService = require('services/HistoryService');
const UserService = require('services/UserService');
const EntryActions = require('actions/EntryActions');
const EntryDatastore = require('datastores/EntryDatastore');
const DirectiveService = require('services/DirectiveService');
const SocketService = require('services/SocketService');

const MOUNT_SELECTOR = '#app';

function altKey(e, code) {
  return e.keyCode === code && (/*e.ctrlKey || e.metaKey || */e.altKey);
}

const AppComponent = {
  el: null,
  path: '',

  mount() {
    UserService.init();
    SocketService.init();

    this.el = document.querySelector(MOUNT_SELECTOR);

    EditorComponent.mount();
    HeadingsComponent.mount();
    BreadcrumbsComponent.mount();
    ModalComponent.mount();

    EntryDatastore.listen(AppComponent.loadCurrentPath);
    EntryActions.events.on('enable-gm-mode', AppComponent.enableGmMode);
    EntryActions.events.on('disable-gm-mode', AppComponent.disableGmMode);

    HistoryService.onPop(state => {
      HistoryService.pushlock = true;
      EntryActions.selectEntry(state.id);
    });

    document.addEventListener('keydown', this.onKeyDown.bind(this));
  },

  loadCurrentPath() {
    EntryDatastore.unlisten(AppComponent.loadCurrentPath);
    let id = EntryDatastore.getIdFromPath(window.location.pathname);
    EntryActions.selectEntry(id);
    EntryDatastore.listen(AppComponent.reloadDirectives);
    AppComponent.reloadDirectives();
  },

  reloadDirectives() {
    DirectiveService.mountAll();
  },

  onKeyDown(e) {
    if (altKey(e, 78)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      HeadingsComponent.onAddClick(null);
      return;
    }
    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopImmediatePropagation();
      ModalComponent.hide();
      return;
    }

    if (altKey(e, 49) && BreadcrumbsComponent.ids[0]) {
      e.preventDefault();
      e.stopImmediatePropagation();
      let parent = BreadcrumbsComponent.ids[0];
      HeadingsComponent.onAddClick(parent);
      return;
    }

    if (altKey(e, 50) && BreadcrumbsComponent.ids[1]) {
      e.preventDefault();
      e.stopImmediatePropagation();
      let parent = BreadcrumbsComponent.ids[1];
      HeadingsComponent.onAddClick(parent);
      return;
    }
  },

  enableGmMode() {
    AppComponent.el.classList.add('gm-mode-enabled');
  },

  disableGmMode() {
    AppComponent.el.classList.remove('gm-mode-enabled');
  },
};

module.exports = AppComponent;