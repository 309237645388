const { bindAll } = require('shared/utils/functions');

const Selectors = {
  MAIN: '[d-dropdown]',
  TRIGGER: '[d-dropdown-trigger]',
  TARGET: '[d-dropdown-target]',
};

const DropdownDirectiveController = {
  open: null,

  registerOpen(directive) {
    if(this.open) {
      this.open.collapse();
    }
    this.open = directive;
  },

  unregisterOpen() {
    this.open = null;
  }
};

class DropdownDirective {
  constructor(el) {
    this.el = el;
    this.triggerEl = this.el.querySelector(Selectors.TRIGGER);
    this.targetEl = this.el.querySelector(Selectors.TARGET);
    this.expanded = false;

    bindAll(this, [
      'onClick',
      '_documentClickListener',
    ]);

    this.triggerEl.addEventListener('click', this.onClick);
  }

  onClick(e) {
    e.stopImmediatePropagation();
    e.preventDefault();
    if(!this.expanded) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  expand() {
    this.expanded = true;
    this.el.classList.add('is-expanded');
    document.addEventListener('click', this._documentClickListener);
    DropdownDirectiveController.registerOpen(this);
    setTimeout(() => {
      this.targetEl.classList.add('is-expanding');
    }, 0);
  }

  collapse() {
    this.expanded = false;
    document.removeEventListener('click', this._documentClickListener);
    this.el.classList.remove('is-expanded');
    DropdownDirectiveController.unregisterOpen(this);
    this.targetEl.classList.remove('is-expanding');
  }

  unmount() {
    this.triggerEl.removeEventListener('click', this.onClick);
  }

  _documentClickListener(e) {
    let insideDropdown = e.target.closest(Selectors.MAIN);
    if(!insideDropdown) {
      this.collapse();
    }
  }
}

DropdownDirective.selector = Selectors.MAIN;

module.exports = DropdownDirective;