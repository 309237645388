const MainConnector = require('connectors/MainConnector');
const EntryDatastore = require('datastores/EntryDatastore');

const GraphConnector = {
  socket: null,

  init() {
    this.socket = MainConnector.get('main');

    this.socket.on('graph-updated', (graph) => {
      EntryDatastore.update(graph);
    });
  },

  newEntry(entry) {
    this.socket.emit('new-entry', entry);
  },

  removeEntries(ids) {
    this.socket.emit('remove-entries', ids);
  },

  moveEntry(id, parent) {
    this.socket.emit('move-entry', {
      id, parent
    });
  },

  updateHeading(id, heading) {
    this.socket.emit('update-heading', id, heading);
  },

  setEntryContent(entryId, content) {
    this.socket.emit('set-content', entryId, content);
  }
};

module.exports = GraphConnector;