const http = require('shared/utils/http-rest');
const EntryDatastore = require('datastores/EntryDatastore');
const CurrentEntryDatastore = require('datastores/CurrentEntryDatastore');
const HeadingsService = require('connectors/GraphConnector');
const EntryService = require('services/EntryService');
const EventHub = require('shared/utils/event-hub');

const State = {
  gmMode: false,
  currentEntryId: null,
  gmAccessible: false,
};

const EntryActions = {
  events: new EventHub(),
  State: State,

  fetchGraph() {
    return http.get('/api/graph')
      .then(graph => {
        EntryDatastore.update(graph);
      });
  },

  newEntry(entry) {
    HeadingsService.newEntry(entry);
  },

  updateEntry(id, entry) {
    HeadingsService.updateHeading(id, entry);
  },

  moveEntry(id, parent) {
    HeadingsService.moveEntry(id, parent);
  },

  removeEntries(entryIds) {
    HeadingsService.removeEntries(entryIds);
  },

  selectEntry(id) {
    State.currentEntryId = id;
    this.events.trigger('selectEntry', id);

    http.get(`/api/data/${id}`)
      .then(datum => {
        CurrentEntryDatastore.update(datum);
      });
  },

  restore(version) {
    EntryService.setContent(version.content);
  },

  autolink() {
    this.events.trigger('autolink');
  },

  toggleGmMode() {
    if(!State.gmAccessible) {
      return;
    }

    if(!State.gmMode) {
      this.enableGmMode();
    } else {
      this.disableGmMode();
    }
  },

  enableGmMode() {
    if(!State.gmAccessible) {
      return;
    }

    State.gmMode = true;
    let id = CurrentEntryDatastore.get()._id;

    http.get(`/api/data/${id}`)
      .then(datum => {
        CurrentEntryDatastore.update(datum);
        this.events.trigger('enable-gm-mode');
      });
  },

  disableGmMode() {
    State.gmMode = false;
    let id = CurrentEntryDatastore.get()._id;

    http.get(`/api/data/${id}`)
      .then(datum => {
        CurrentEntryDatastore.update(datum);
        this.events.trigger('disable-gm-mode');
      });
  },

  enableAccessToGmMode() {
    State.gmAccessible = true;
  },
};

module.exports = EntryActions;