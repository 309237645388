const io = require('socket.io-client');
const UserService = require('services/UserService');

const MainConnector = {
  sockets: {},

  init() {
    this.sockets.main = io(`${location.protocol}//${location.host}`, {
      query: `u_var=${UserService.id}`
    });
  },

  get(socket) {
    return this.sockets[socket];
  },
};

module.exports = MainConnector;