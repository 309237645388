const ModalComponent = require('components/ModalComponent');

class TextInputModal {
  constructor(params) {
    this.params = Object.assign({
      primaryActionLabel: 'Save',
      type: 'text',
    }, params);
  }

  renderHeader() {
    return `<h2 class="modal-title">${this.params.title}</h2>`;
  }

  renderBody() {
    return `<form id="new-entry-form">
      <div class="field" flex row center-items>
        <label grow>${this.params.label}</label>
        <input type="${this.params.type}" name="title" value="" placeholder="${this.params.placeholder}" />
      </div>
    </form>`;
  }

  renderFooter() {
    return `<div class="action-bar">
      <button id="modal-cancel" class="modal-action secondary">Cancel</button>
      <button id="modal-save" class="modal-action primary">${this.params.primaryActionLabel}</button>
    </div>`;
  }

  bindEvents(el) {
    this.titleInput = el.querySelector('[name="title"]');

    el.querySelector('#modal-cancel').addEventListener('click', () => {
      ModalComponent.hide();
    });
    el.querySelector('#modal-save').addEventListener('click', () => {
      this._save();
    });
    el.querySelector('#new-entry-form').addEventListener('submit', (e) => {
      e.preventDefault();
      this._save();
    });
  }

  onShow() {
    this.titleInput.focus();
  }

  _save() {
    let value = this.titleInput.value;

    this.params.onSave({
      modal: this,
      value
    });
  }

  error() {
    this.titleInput.closest('.field').classList.add('danger');
  }
}

module.exports = TextInputModal;