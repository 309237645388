const fn = require('fnjs');

function childrenFlat(headings, id) {
  return fn.filter(headings, heading => {
    return heading.parent === id;
  });
}

function childrenRecursive(headings, id) {
  let children = childrenFlat(headings, id);
  let count = Object.keys(children).length;
  if(count > 0) {
    fn.forEach(children, (child, childId) => {
      children = {
        ...children,
        ...childrenRecursive(headings, childId)
      };
    })
  }
  return children;
}

module.exports = {
  childrenRecursive
};