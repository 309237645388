const ModalComponent = require('components/ModalComponent');

class ConfirmModal {
  constructor(params) {
    this.params = Object.assign({
      mainActionClass: 'primary'
    }, params);
  }

  classes() {
    return ['confirm-modal'];
  }

  renderHeader() {
    return `<h2 class="modal-title">${this.params.title}</h2>`;
  }

  renderBody() {
    return ``;
  }

  renderFooter() {
    return `<div class="action-bar">
      <button id="modal-cancel" class="modal-action secondary">No</button>
      <button id="modal-save" class="modal-action ${this.params.mainActionClass}">Yes</button>
    </div>`;
  }

  bindEvents(el) {
    el.querySelector('#modal-cancel').addEventListener('click', () => {
      ModalComponent.hide();
    });
    el.querySelector('#modal-save').addEventListener('click', () => {
      this.params.onConfirm();
    });
  }
}

module.exports = ConfirmModal;