const dom = require('shared/utils/dom');

const DirectiveService = {
  DirectiveClasses: [
    require('directives/DropdownDirective'),
    require('directives/TooltipDirective'),
    require('directives/IconDirective'),
  ],
  directives: [],

  unmountAll() {
    this.directives.forEach(d => {
      d.unmount();
    });
    this.directives = [];
  },

  mountAll() {
    this.DirectiveClasses.forEach(this.mount.bind(this));
  },

  mount(directive) {
    let directives = dom.findAll(directive.selector)
      .map(el => new directive(el));
    this.directives = this.directives.concat(directives);
  }
};

module.exports = DirectiveService;