const { bindAll } = require('shared/utils/functions');
const dom = require('shared/utils/dom');

const Selectors = {
  MAIN: '[d-tooltip]',
};

const DataAttributes = {
  TEXT: 'tooltip-text'
};

const TooltipDirectiveController = {
  id: 0,
  nextId() {
    return TooltipDirectiveController.id++;
  },

  directives: {},

  register(directive) {
    let id = directive.containerEl.getAttribute('data-tooltip-id');
    TooltipDirectiveController.directives[id] = directive;
  },

  unregister(directive) {
    let id = directive.containerEl.getAttribute('data-tooltip-id');
    delete TooltipDirectiveController.directives[id];
  },

  directiveFor(el) {
    let id = el.getAttribute('data-tooltip-id');
    return TooltipDirectiveController.directives[id];
  },
};

class TooltipDirective {
  constructor(el) {
    this.containerEl = el;
    if(!this.containerEl.getAttribute('data-tooltip-id')) {
      this.containerEl.setAttribute('data-tooltip-id', TooltipDirectiveController.nextId());
    }

    bindAll(this, [
      'onHover',
      '_onMouseLeave',
    ]);

    this.containerEl.addEventListener('mouseenter', this.onHover);
    TooltipDirectiveController.register(this);
  }

  onHover(e) {
    e.stopImmediatePropagation();
    e.preventDefault();

    this.show();
  }

  _onMouseLeave() {
    this.hide();
  }

  show() {
    this.render();

    setTimeout(() => {
      this.containerEl.classList.add('is-tooltip-visible');
      this.containerEl.addEventListener('mouseleave', this._onMouseLeave);
    }, 0);
  }

  hide() {
    this.containerEl.removeEventListener('mouseleave', this._onMouseLeave);
    this.containerEl.classList.remove('is-tooltip-visible');
  }

  unmount() {
    TooltipDirectiveController.unregister(this);
    this.containerEl.removeEventListener('mouseenter', this.onHover);
  }

  render() {
    let text = this.containerEl.getAttribute(`data-${DataAttributes.TEXT}`);
    if(this.tooltipEl) {
      this.tooltipEl.innerText = text;
    } else {
      this.tooltipEl = dom.createElement(`<div d-tooltip-text class="tooltip-text">${text}</div>`);
      this.containerEl.appendChild(this.tooltipEl);
    }
  }

  static rerender(el) {
    let directive = TooltipDirectiveController.directiveFor(el);
    if(directive) {
      directive.render();
    }
  }
}

TooltipDirective.selector = Selectors.MAIN;

module.exports = TooltipDirective;