const fn = require('fnjs');
const Datastore = require('./BaseDatastore');
const { normalizePath } = require('utils/location');

class BaseEntryDatastore extends Datastore {
  pathTo(id) {
    let path = [];
    let part = this.data[id];

    while(part) {
      path.push(part.slug);
      part = this.data[part.parent];
    }

    return path.reverse();
  }

  idsTo(id) {
    let path = [];
    let part = this.data[id];

    while(part) {
      path.push(id);
      id = part.parent;
      part = this.data[part.parent];
    }

    return path.reverse();
  }

  titlesTo(id) {
    let path = [];
    let part = this.data[id];

    while(part) {
      path.push(part.title);
      part = this.data[part.parent];
    }

    return path.reverse();
  }

  getGraphForSlug(slug) {
    return fn.filter(this.data, heading => heading.slug === slug);
  }

  getIdFromPath(path) {
    path = normalizePath(path);
    let last = path[path.length - 1];
    let matches = this.getGraphForSlug(last);
    let ids = Object.keys(matches);

    if(ids.length === 1) {
      return ids[0];
    } else if(ids.length === 0) {
      return null;
    } else {
      let slug = path[path.length - 2];
      let possibleParents = fn.filter(matches.map(m => this.data[m.parent]), parent => {
        return parent.slug === slug;
      });

      //TODO make this more abstract, only 2 levels deep rn
      if(Object.keys(possibleParents).length === 1) {
        return ids[0];
      } else {
        return null;
      }
    }
  }
}

const EntryDatastore = new BaseEntryDatastore();
module.exports = EntryDatastore;