const HistoryService = {
  pushlock: false,

  push(state, title, path) {
    if(!this.pushlock) {
      history.pushState(state, title, path);
      location.title = title;
    }
  },

  onPop(callback) {
    window.onpopstate = function(event) {
      callback(event.state);
    };
  }
};

module.exports = HistoryService;