function toRadix(N, radix) {
  var HexN = "", Q = Math.floor(Math.abs(N)), R;
  while(true) {
    R = Q % radix;
    // Base 61 - all but lower case 'x'
    HexN = "0123456789abcdefghijklmnopqrstuvwyzABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(R)
      + HexN;
    Q = (Q - R) / radix;
    if(Q == 0) break;
  }
  return ((N < 0) ? "-" + HexN : HexN);
}

function reverseRadix(hash) {
  var value = 0, values = [],
    list = "0123456789abcdefghijklmnopqrstuvwyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for(var i = 0; i < hash.length; i++) {
    var val = list.indexOf(hash[i]);
    if(val > -1)
      value += val * Math.pow(61, hash.length - 1 - i);
  }

  return value;
}

function scaleRect(from, to) {
  let ratioWidth = from.width / to.width;
  let ratioHeight = from.height / to.height;
  let ratio = ratioWidth > ratioHeight ? ratioWidth : ratioHeight;

  return {
    width: from.width / ratio,
    height: from.height / ratio
  };
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

module.exports = {
  toRadix,
  reverseRadix,
  scaleRect,
  uuidv4
};