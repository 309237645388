const fn = require('fnjs');
const dom = require('shared/utils/dom');
const { debounce } = require('shared/utils/functions');
const ModalComponent = require('components/ModalComponent');
const HeadingsDatastore = require('datastores/EntryDatastore');

function normalizeForFilter(text) {
  return text.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase();
}

class NewEntryModal {
  constructor(params) {
    this.params = params;
    this.currentParent = params.parent;
  }

  renderHeader() {
    return `<h2 class="modal-title">${this.params.title}</h2>`;
  }

  classes() {
    return ['new-entry-modal'];
  }

  renderBody() {
    return `<form id="new-entry-form" action="#">
      <div class="field" flex row center-items>
        <label grow>${this.params.label}</label>
        <input name="title" value="" placeholder="${this.params.placeholder}" />
      </div>
      <div class="field" flex row center-items>
        <label grow>Parent</label>
        <div class="autocomplete-input">
          <input name="parent" value="" placeholder="Parent" autocomplete="off"/>
          <ul class="autocomplete-suggestions"></ul>
        </div>
      </div>
      <input class="field hidden" type="submit" />
    </form>`;
  }

  renderFooter() {
    return `<div class="action-bar">
      <button id="modal-cancel" class="modal-action secondary">Cancel</button>
      <button id="modal-save" class="modal-action primary">Save</button>
    </div>`;
  }

  bindEvents(el) {
    this.titleInput = el.querySelector('[name="title"]');
    this.parentInput = el.querySelector('[name="parent"]');
    this.autocompleteEl = el.querySelector('.autocomplete-input');
    this.suggestionsListEl = el.querySelector('.autocomplete-suggestions');

    el.querySelector('#modal-cancel').addEventListener('click', () => {
      ModalComponent.hide();
    });
    el.querySelector('#modal-save').addEventListener('click', () => {
      this._save();
    });
    el.querySelector('#new-entry-form').addEventListener('submit', (e) => {
      e.preventDefault();
      this._save();
    });

    let headings = HeadingsDatastore.get();
    this.suggestions = fn.map(headings, (heading, id) => {
      let text = HeadingsDatastore.titlesTo(id).join(' > ');

      return {
        text,
        value: id,
        queryable: normalizeForFilter(text),
      };
    });

    fn.forEach(this.suggestions, (suggestion, id) => {
      this.suggestionsListEl.innerHTML = this.suggestionsListEl.innerHTML + `<li class="autocomplete-suggestion" data-id="${id}">${suggestion.text}</li>`;
    });


    this.parentInput.addEventListener('keydown', debounce(this.filter.bind(this), 250));

    dom.delegate(this.autocompleteEl, 'click', {
      '.autocomplete-suggestion': (e) => {
        let id = e.target.getAttribute('data-id');
        let suggestion = this.suggestions[id];
        this.currentParent = id;
        this.parentInput.value = suggestion.text;
      }
    }, this);

    if(this.currentParent) {
      this.parentInput.value = HeadingsDatastore.titlesTo(this.currentParent).join(' > ');
    }

    this.parentInput.addEventListener('focus', (e) => {
      this.autocompleteEl.classList.add('autocomplete-visible');
    });
    this.parentInput.addEventListener('blur', (e) => {
      setTimeout(() => {
        this.autocompleteEl.classList.remove('autocomplete-visible');
      }, 50);
    });
  }

  filter() {
    let query = normalizeForFilter(this.parentInput.value);

    this.autocompleteEl.classList.remove('is-filtering');
    let suggestions = dom.findAll(this.suggestionsListEl, '.autocomplete-suggestion');

    if(!query) {
      suggestions.forEach(sug => {
        sug.classList.remove('is-visible');
      });
    }

    if(query) {
      this.autocompleteEl.classList.add('is-filtering');

      suggestions.forEach(sug => {
        sug.classList.remove('is-visible');
        let suggestion = this.suggestions[sug.getAttribute('data-id')];

        if(suggestion && suggestion.queryable.search(query) > -1) {
          sug.classList.add('is-visible');
        }
      });
    }
  }

  onShow() {
    this.titleInput.focus();
  }

  _save() {
    let value = this.titleInput.value;

    this.params.onSave({
      value,
      parent: this.currentParent
    });
  }
}

module.exports = NewEntryModal;