const MOUNT_SELECTOR = '#modal';

const ModalComponent = {
  el: null,
  headerEl: null,
  bodyEl: null,
  footerEl: null,
  glassEl: null,

  mount() {
    this.el = document.querySelector(MOUNT_SELECTOR);
    this.headerEl = this.el.querySelector('.modal-header');
    this.bodyEl = this.el.querySelector('.modal-body');
    this.footerEl = this.el.querySelector('.modal-footer');
    this.glassEl = this.el.querySelector('.modal-glass');

    this.glassEl.addEventListener('click', () => {
      this.hide();
    });
  },

  show(modal) {
    if(modal.classes) {
      modal.classes().forEach(cls => {
        this.el.classList.add(cls);
      });
    }
    this.headerEl.innerHTML = modal.renderHeader();
    this.bodyEl.innerHTML = modal.renderBody();
    this.footerEl.innerHTML = modal.renderFooter();
    modal.bindEvents(this.el, this);
    this.el.classList.add('is-visible');

    if(modal.onShow) {
      modal.onShow();
    }
  },

  hide() {
    this.el.className = '';
    this.headerEl.innerHTML = '';
    this.bodyEl.innerHTML = '';
    this.footerEl.innerHTML = '';
  }
};

module.exports = ModalComponent;