const config = require('shared/config/shared');
const dom = require('shared/utils/dom');
const EntryActions = require('actions/EntryActions');
const EntryDatastore = require('datastores/EntryDatastore');
const CurrentEntryDatastore = require('datastores/CurrentEntryDatastore');
const HistoryService = require('services/HistoryService');
const ModalComponent = require('components/ModalComponent');
const HistoryModal = require('modals/HistoryModal');
const TextInputModal = require('modals/TextInputModal');

const MOUNT_SELECTOR = '#breadcrumbs-list';

const BreadcrumbsComponent = {
  el: null,
  listEl: null,
  breadcrumbs: [],
  titlecrumbs: [],

  mount() {
    this.el = document.querySelector('#breadcrumbs');
    this.listEl = document.querySelector(MOUNT_SELECTOR);

    dom.delegate(this.el, 'click', {
      '.action.history': this._onHistoryClick,
      '.action.autolink': this._onAutolinkClick,
      '.action.gm-mode': this._onGMModeClick,
    }, this);

    EntryActions.events.on('selectEntry', this.update.bind(this));
    EntryActions.events.on('enable-gm-mode', this.enableGmMode.bind(this));
    EntryActions.events.on('disable-gm-mode', this.disableGmMode.bind(this));
    this.render();
  },

  update(id) {
    this.ids = EntryDatastore.idsTo(id);
    this.breadcrumbs = EntryDatastore.pathTo(id);
    this.titlecrumbs = EntryDatastore.titlesTo(id);

    HistoryService.push(
      { id },
      `${this.titlecrumbs.join(' > ')} | Dungeon Pad`,
      `/${this.breadcrumbs.join('/')}`
    );
    HistoryService.pushlock = false;

    this.render();
  },

  render() {
    if(this.breadcrumbs.length < 1) {
      return;
    }

    this.listEl.innerHTML = `
      <li class="divider">&gt;</li>
      ${this.titlecrumbs.map(breadcrumb => {
        return `<li class="breadcrumb">
          <span class="heading-text">${breadcrumb}</span>
        </li>`
      }).join(`<li class="divider">/</li>`)}
    `;
  },

  _onHistoryClick(e) {
    if(e.target.getAttribute('disabled') === 'true') {
      return;
    }

    let current = CurrentEntryDatastore.get();
    ModalComponent.show(new HistoryModal({
      history: current.history,
      title: current.title,
      current: current.content,
      onRestore: (version) => {
        ModalComponent.hide();
        EntryActions.restore(version);
      }
    }));
  },

  _onAutolinkClick() {
    EntryActions.autolink();
  },

  _onGMModeClick() {
    if(EntryActions.State.gmAccessible) {
      EntryActions.toggleGmMode();
    } else {
      ModalComponent.show(new TextInputModal({
        type: 'password',
        title: 'Enter GM Password',
        label: 'Password',
        placeholder: `Kan'tFüll This`,
        primaryActionLabel: 'Okay',
        onSave({ value, modal }) {
          if(value === config.gmPassword) {
            ModalComponent.hide();
            EntryActions.enableAccessToGmMode();
            EntryActions.toggleGmMode();
          } else {
            modal.error();
          }
        },
      }));
    }
  },

  enableGmMode() {
    this.el.querySelector('.action.gm-mode').setAttribute('data-tooltip-text', 'Exit GM-Mode');
    this.el.querySelector('.action.history').setAttribute('disabled', 'true');
  },

  disableGmMode() {
    this.el.querySelector('.action.gm-mode').setAttribute('data-tooltip-text', 'Enter GM-Mode');
    this.el.querySelector('.action.history').removeAttribute('disabled');
  },
};

module.exports = BreadcrumbsComponent;