const fn = require('fnjs');

function insertLinks(quill, headings, linkCallback) {
  let text = quill.getText();

  fn.forEach(headings, (heading, id) => {
    let label = heading.title;
    let i = text.search(new RegExp(label, 'i'));
    let min = -1;

    while(i > min) {
      let contents = quill.getContents(i, label.length + 1);

      if(contents.ops.length === 1 && label.length > 3) {
        let link = linkCallback(id);

        quill.updateContents({
          ops: [
            { retain: i },
            { retain: label.length, attributes: {
              link
            } }
          ]
        }, 'autolink');
      }

      min = i + label.length;
      i = min + text.substring(min).indexOf(label);
    }
  });
}

module.exports = {
  insertLinks
};