const { uuidv4 } = require('shared/utils/math');

const PREFIX = 'dungeon-pad';

const UserService = {
  id: null,

  init() {
    this.verifyUserId();
  },

  verifyUserId() {
    this.id = this.readId();

    if(!this.id) {
      this.id = uuidv4();
      this.saveId();
    }
  },

  readId() {
    return localStorage.getItem(`${PREFIX}:userId`);
  },

  saveId() {
    localStorage.setItem(`${PREFIX}:userId`, this.id);
  },
};

module.exports = UserService;